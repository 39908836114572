// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"8NmtC8akQ1MkVqnFc6WXj"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/dashboard/assets/prod";import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://dc94e8a5b89043f291f64d0c958a7673@o488647.ingest.us.sentry.io/5732040',

  // only enable in production
  enabled: process.env.ENVIRONMENT === 'production',

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});

console.debug('Sentry-client initialized');
